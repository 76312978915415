<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/register/distributor/components/RegisterForm.vue
 * @Author: 张兴业
 * @Date: 2020-12-14 20:48:25
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-03-01 18:52:07
-->

<template>
  <div class="form_box">
    <div class="title">现在入驻，即刻成为火龙购商家</div>
    <el-form
      ref="form"
      class="form"
      :model="form"
      :rules="rules"
      @keyup.enter.native="onSubmit"
    >
      <phone-input v-model="form.phone" @prefixChange="prefixChange" />
      <code-ipnut
        v-model="form.code"
        :disabled-send="disabledSend"
        :sending="sending"
        @send="send"
      />
      <passwd-input v-model="form.passwd" />
      <el-form-item>
        <el-button
          :loading="loading"
          :disabled="disabledSubmit"
          type="primary"
          class="join"
          @click="onSubmit()"
        >
          立即入驻
        </el-button>
      </el-form-item>
      <agreement v-model="agree" />
      <!-- <had-registered /> -->
    </el-form>
  </div>
</template>

<script>
const PhoneInput = () => import("../../store/components/PhoneInput");
const CodeIpnut = () => import("../../store/components/CodeIpnut");
const PasswdInput = () => import("../../store/components/PasswdInput");
const Agreement = () => import("../../store/components/Agreement");
// const HadRegistered = () => import("./HadRegistered");
import { verifyPhone, verifyPasswd } from "@/utils/pattern.js";
import { isMobileTerminal } from "@/utils/pattern.js";

export default {
  components: { PhoneInput, CodeIpnut, PasswdInput, Agreement },
  data() {
    var validatePhone = (rule, value, callback) => {
      if (this.form.phone === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!verifyPhone(this.form.phone, this.prefix)) {
          callback(new Error("手机号码格式不正确"));
        } else {
          callback();
        }
      }
    };
    var validateCode = (rule, value, callback) => {
      if (this.form.code === "") {
        callback(new Error("请输入短信验证码"));
      } else {
        if (this.form.code.length !== 6) {
          callback(new Error("短信验证码不正确"));
        } else {
          callback();
        }
      }
    };
    var validatePasswd = (rule, value, callback) => {
      if (this.form.passwd === "") {
        callback(new Error("请设置密码"));
      } else {
        if (!verifyPasswd(this.form.passwd)) {
          callback(new Error("密码为6-12位数字或字母"));
        } else {
          callback();
        }
      }
    };
    return {
      loading: false,
      sending: false,
      prefix: "+86",
      agree: "0",
      form: {
        phone: "",
        code: "",
        passwd: "",
      },
      rules: {
        phone: [{ validator: validatePhone, trigger: "blur" }],
        code: [{ validator: validateCode, trigger: "blur" }],
        passwd: [{ validator: validatePasswd, trigger: "blur" }],
      },
    };
  },
  computed: {
    disabledSend() {
      const { phone } = this.form;
      if (verifyPhone(phone, this.prefix)) {
        return false;
      }
      return true;
    },
    disabledSubmit() {
      const { phone, code, passwd } = this.form;
      if (verifyPhone(phone, this.prefix)) {
        if (code.length === 6) {
          if (verifyPasswd(passwd)) {
            return false;
          }
        }
      }
      return true;
    },
  },
  mounted() {
    console.log(window.location);
  },
  methods: {
    prefixChange(prefix) {
      this.prefix = prefix;
    },
    send(callback) {
      const { phone } = this.form;
      if (!verifyPhone(phone, this.prefix)) {
        this.$message({
          message: "您输入的手机号格式不正确",
          type: "error",
        });
        return false;
      }
      const data = {
        phone,
        kind: "DistributorRegister",
      };
      const url = "/api/front/user/sendSms";
      this.sending = true;
      this.$request(url, {
        method: "POST",
        data,
      })
        .then((res) => {
          this.sending = false;
          if (res.code === 1) {
            this.$message({
              message: "验证码已发送到您的手机",
              type: "success",
            });
            callback();
          }
        })
        .catch(() => {
          this.sending = false;
        });
    },
    onSubmit() {
      if (this.agree === "0") {
        this.$message({
          message: "您尚未阅读并同意用户协议、隐私政策",
          type: "error",
        });
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          const { phone, code: phoneCode, passwd } = this.form;
          const { code, email, encrypt } = this.$route.query;
          const data = {
            code,
            email,
            encrypt: encodeURIComponent(encrypt),
            phone,
            phoneCode,
            password: passwd,
          };
          const url = "/api/front/distributor/distributorRegister";
          this.loading = true;
          this.$request(url, {
            method: "POST",
            data,
          })
            .then((res) => {
              this.loading = false;
              if (res.code === 1100) {
                if (isMobileTerminal()) {
                  this.$dialog
                    .confirm({
                      title: "提示",
                      message: "该账号已注册，请前往商家端登录",
                    })
                    .then(() => {
                      this.goDistributorTerminal();
                    })
                    .catch(() => {});
                } else {
                  this.$confirm("该账号已注册，请前往商家端登录", "提示", {
                    confirmButtonText: "前往",
                    cancelButtonText: "不，谢谢!",
                    type: "success",
                  })
                    .then(() => {
                      this.goDistributorTerminal();
                    })
                    .catch(() => {});
                }
              }
              if (res.code === 1) {
                this.$confirm("注册成功，立即前往商家端登录", "提示", {
                  confirmButtonText: "前往",
                  showCancelButton: false,
                  // cancelButtonText: "No，Thanks!",
                  type: "success",
                })
                  .then(() => {
                    this.goDistributorTerminal();
                  })
                  .catch(() => {});
              }
            })
            .catch(() => {
              this.loading = true;
              // this.$router.push("result");
            });
        } else {
          return false;
        }
      });
    },
    goDistributorTerminal() {
      const { origin } = window.location;
      if (origin === "https://d-www.hooloogo.com") {
        window.location.replace("https://d-distributor.hooloogostore.com/");
      } else if (origin === "https://t-www.hooloogo.com") {
        window.location.replace("https://t-distributor.hooloogostore.com/");
      } else if (origin === "https://www.hooloogo.com") {
        window.location.replace("https://int.hooloogostore.com/");
      } else {
        window.location.replace("https://int.hooloogostore.com/");
      }
      // history.pushState(null, null, document.URL);
      // window.addEventListener("popstate", function() {
      //   history.pushState(null, null, document.URL);
      // });
    },
  },
};
</script>

<style lang="less" scoped>
.form_box {
  background: white;
  padding: 60px 70px;
  border-radius: 30px;

  .title {
    font-size: 25px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    white-space: nowrap;
    text-align: left;
  }

  .form {
    margin-top: 48px;
    min-width: 440px;

    .join {
      width: 100%;
      margin-top: 40px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .form_box {
    padding: 24px 16px;

    .title {
      white-space: normal;
    }

    .form {
      min-width: 320px;
      margin-top: 24px;

      .join {
        width: 100%;
        margin-top: 24px;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .form_box {
    padding: 12px;

    .title {
      white-space: normal;
    }

    .form {
      min-width: 280px;
      margin-top: 10px;

      .join {
        width: 100%;
        margin-top: 0px;
      }
    }
    ::v-deep .had_registered {
      margin-top: 8px;
    }
  }
}
</style>
